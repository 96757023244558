import React, { useEffect, useState } from 'react';
import { RouteRequest, RouteContent, NotFoundView } from './core/Router';
import { Layout } from './components/Layout';
import { Logger } from "./infrastructure/Logger";
import { CacheItem } from './infrastructure/Cache';
import { Configuration } from "./infrastructure/Configuration";
import { PushCommandClient } from "./infrastructure/NotificationSubscribers";
import { CapabilityName } from "./infrastructure/Constants";
import { CapabilityRegistry } from "./infrastructure/Capabilities";
import { BackSplashComponent } from "./capabilities/BackSplashComponent";
import { LoaderComponent } from "./capabilities/LoaderComponent";
import { ModalComponent } from "./capabilities/ModalComponent";
import { NotificationComponent } from "./capabilities/NotificationComponent";
import { CapabilitiesContext, SettingsContext, UserContext } from "./infrastructure/Contexts";
import { Home, WebHookView } from './pages/Home';
import { AuditView } from './pages/AuditView';
import { LegacyModalComponent } from './core/Modal';
import { LegacyConfirmationComponent } from './core/Confirmation';
import { LegacyLoaderComponent } from './core/Loader';
import { LegacyAlertsComponent } from './core/Alerts';
import { ConfigurationHomeView } from './pages/Configuration/ConfigurationHomeView';
import { IndustryView, IndustryCategoryView } from './pages/Configuration/Industry/IndustryView';
import { SalesChannelView } from './pages/Configuration/SalesChannels/SalesChannelView';
import { EstimatorFeatureView } from './pages/Configuration/EstimatorFeatures/EstimatorFeatureView';
import { ProductConsistencyAttributeView, ProductMoistureAttributeView, ProductAromaAttributeView, ProductLightSensitivityAttributeView, ProductShelfLifeTimeAttributeView, ProductChildResistanceAttributeView, ProductAgressivnessAttributeView, ProductEmissionAttributeView, ProductTemperatureAttributeView, ProductOxygenSensitivityAttributeView } from './pages/Configuration/ProductAttributes/ProductAttributeViews';
import { ConfigurationProductAttributesHomeView } from './pages/Configuration/ProductAttributes/ConfigurationProductAttributesHomeView';
import { ConfigurationPackagingAttributesHomeScreen } from './pages/Configuration/PackagingAttributes/ConfigurationPackagingAttributesHomeScreen';
import { PackagingFinishTypeAttributeView, PackagingTransparencyAttributeView, PackagingSustainabilityAttributeView, PackagingFillTypeAttributeView, PackagingSealTypeAttributeView, PackagingSealWrapTypeAttributeView, PackagingBarrierTypeAttributeView, PackagingApplicationAttributeView, ManufacturingOptionView, PackagingConvertingMethodAttributeListView, PackagingConvertingMethodAttributeView, PackagingConvertingMethodAttributeEditView } from './pages/Configuration/PackagingAttributes/PackagingAttributeViews';
import { ConfigurationFeatureAttributesHomeScreen } from './pages/Configuration/FeatureAttributes/ConfigurationFeatureAttributesHomeScreen';
import { VentAttributeView, FeatureZipperAttributeView, FeatureHangholeAttributeView, FeatureTearnotchAttributeView, FeatureBottomFillAttributeView, FeatureCoreAttributeView, FeatureColorSpecAttributeView, FeatureEyemarkAttributeView, FeatureDoubleCutAttributeView, FeatureRoundedCornerAttributeView, FeatureSealWidthAttributeView, QrCodeTypeAttributeView, UnwindDirectionView, FeatureGussetTypeAttributeView, OutsourcedFeatureView } from './pages/Configuration/FeatureAttributes/FeatureAttributeViews';
import { FeatureDefaultLocationListEditView, FeatureDefaultLocationListView } from './pages/Configuration/FeatureAttributes/FeatureDefaultLocationViews';
import { PhysicalPropertiesListView, PhysicalPropertiesDetailsView, PhysicalPropertiesEditView } from './pages/Configuration/StructureSpecs/PhysicalPropertiesView';
import { ConfigurationStructureSpecsHomeScreen, StructureSpecFeatureView, StructureSpecTestMethodsView, StructureSpecPropertiesDefinitionView, ItemsView, FilmWidthDefinitionView, FilmWidthMappingView, PrelamRegistryView } from './pages/Configuration/StructureSpecs/ConfigurationStructureSpecsHomeScreen';
import { StandardSizesListView, StandardSizesEditorView } from './pages/Configuration/Dimensions/StandardSizesView';
import { RegionalSupportConfigurationView, LanguageListView, TranslationView } from './pages/Configuration/Regions/RegionViews';
import { PressConfigurationView } from './pages/Configuration/Press/PressConfigurationView';
import { DielineConfigurationHomeView, DielineConfigurationConvertingMethodsView, DielineConfigurationHangHolesView, DielineConfigurationTearNotchesView, DielineConfigurationZippersView, DielineConfigurationEyemarksView, DielineConfigurationBottomFillsView, DielineConfigurationValvesView, DielineConfigurationSealWidthView, DielineConfigurationRoundedCornersView, DielineConfigurationDoubleCutView, DielineConfigurationQrCodeTypeView, DielineConfigurationGussetTypeView, DielineConfigurationFinishTypesView } from './pages/Configuration/Dieline/DielinesConfigurationView';
import { SettingsView } from './pages/Configuration/Settings/SettingsView';
import { ReleaseView } from './pages/Configuration/Releases/ReleaseView';
import { ProductHomeView, ProductListView, ProductDisplayView, ProductEditView, ProductReviewView, ProductReviewEditView } from './pages/Product/ProductHomeView';
import { StructureSpecSheetRegistryView } from './pages/StructureSpec/StructureSpecSheetRegistryView';
import { StructureSpecListView, StructureSpecImportView, StructureSpecEditView, StructureSpecView } from './pages/StructureSpec/StructureSpecHomeView';
import { StructureSpecRequestView } from './pages/StructureSpec/StructureSpecRequestView';
import { ProductGroupListView, ProductGroupDisplayView, ProductGroupEditView } from './pages/PackagingGroup/PackagingGroupHomeView';
import { RulesHomeView, RuleDetailsView, RuleEditView } from './pages/Rules/RulesHomeView';
import { CertificationHomeView, CertificationTypeView, CertificateView } from './pages/Certification/CertificationHomeView';





import '../src/styles/glyphicons.css';
import "./global.css";
import "./mobile.css";


export default function App() {
    
    const capabilities = new CapabilityRegistry();

    const [config, setConfig] = useState(null);

    useEffect(() => {
        Logger.writeDebug("Application initializing");
        async function init() {

            let configuration = new Configuration();

            try {

                var cfg = await configuration.getConfigAsync();

                if (cfg) {
                    var notificationClient = new PushCommandClient(cfg.settings["notifications:PushWebSocketUri"], cfg.settings["security:ClientId"], cfg.user.sid);
                    var notificationRegistry = notificationClient.getSubscriberRegistry();

                    notificationRegistry.registerHandler("App.onCommandReceived", onCommandReceived);

                    var config = {
                        user: cfg.user,
                        settings: cfg.settings,
                        capabilities: capabilities,
                        notificationSubscribers: notificationRegistry
                    }

                    Logger.writeDebug("config", config);

                    setConfig(config);
                    Logger.writeDebug("Finished");
                }
            }
            catch (ex) {
                Logger.writeError("Application initialization has failed. Redirecting to login page.", ex);
                window.location.replace("account/login");
            }
        }
        init();
    }, []);

    const onCommandReceived = (command) => {
        Logger.writeDebug(`Command ${command.Action} received.`, command);
        if (command.Action == "release-created") {
            window.Alerts.showInfo(`User ${command.Properties.user} created new product management release ${command.Properties.version}.`, 'Release Created');
        }
    }

    const onRegister = (router) => {
        router.registerRoute('', <Home />);
        router.registerRoute('/', <Home />);
        router.registerRoute('/home', <Home />);
        router.registerRoute('/notfound', <NotFoundView />, 'Not Found');
        router.registerRoute('/home/webhooks', <WebHookView />);
        router.registerRoute('/configuration', <ConfigurationHomeView />);
        router.registerRoute('/configuration/industries', <IndustryView />);
        router.registerRoute('/configuration/industries/{id}/categories', <IndustryCategoryView />);
        router.registerRoute('/configuration/saleschannels', <SalesChannelView />);
        router.registerRoute('/configuration/estimatorfeatures', <EstimatorFeatureView />);

        router.registerRoute('/configuration/products/attributes', <ConfigurationProductAttributesHomeView />);
        router.registerRoute('/configuration/products/attributes/consistency', <ProductConsistencyAttributeView />);
        router.registerRoute('/configuration/products/attributes/consistency/{id}', <ProductConsistencyAttributeView />);
        router.registerRoute('/configuration/products/attributes/moisture', <ProductMoistureAttributeView />);
        router.registerRoute('/configuration/products/attributes/moisture/{id}', <ProductMoistureAttributeView />);
        router.registerRoute('/configuration/products/attributes/aroma', <ProductAromaAttributeView />);
        router.registerRoute('/configuration/products/attributes/aroma/{id}', <ProductAromaAttributeView />);
        router.registerRoute('/configuration/products/attributes/light', <ProductLightSensitivityAttributeView />);
        router.registerRoute('/configuration/products/attributes/light/{id}', <ProductLightSensitivityAttributeView />);
        router.registerRoute('/configuration/products/attributes/lifetime', <ProductShelfLifeTimeAttributeView />);
        router.registerRoute('/configuration/products/attributes/lifetime/{id}', <ProductShelfLifeTimeAttributeView />);
        router.registerRoute('/configuration/products/attributes/child', <ProductChildResistanceAttributeView />);
        router.registerRoute('/configuration/products/attributes/child/{id}', <ProductChildResistanceAttributeView />);
        router.registerRoute('/configuration/products/attributes/aggressivness', <ProductAgressivnessAttributeView />);
        router.registerRoute('/configuration/products/attributes/aggressivness/{id}', <ProductAgressivnessAttributeView />);
        router.registerRoute('/configuration/products/attributes/emission', <ProductEmissionAttributeView />);
        router.registerRoute('/configuration/products/attributes/emission/{id}', <ProductEmissionAttributeView />);
        router.registerRoute('/configuration/products/attributes/temperature', <ProductTemperatureAttributeView />);
        router.registerRoute('/configuration/products/attributes/temperature/{id}', <ProductTemperatureAttributeView />);
        router.registerRoute('/configuration/products/attributes/oxygensensitivity', <ProductOxygenSensitivityAttributeView />);
        router.registerRoute('/configuration/products/attributes/oxygensensitivity/{id}', <ProductOxygenSensitivityAttributeView />);

        router.registerRoute('/configuration/packaging/attributes', <ConfigurationPackagingAttributesHomeScreen />);
        router.registerRoute('/configuration/packaging/attributes/finish', <PackagingFinishTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/finish/{id}', <PackagingFinishTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/transparency', <PackagingTransparencyAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/transparency/{id}', <PackagingTransparencyAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/sustainability', <PackagingSustainabilityAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/sustainability/{id}', <PackagingSustainabilityAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/fill', <PackagingFillTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/fill/{id}', <PackagingFillTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/seal', <PackagingSealTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/seal/{id}', <PackagingSealTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/wrap', <PackagingSealWrapTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/wrap/{id}', <PackagingSealWrapTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/barrier', <PackagingBarrierTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/barrier/{id}', <PackagingBarrierTypeAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/application', <PackagingApplicationAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/application/{id}', <PackagingApplicationAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/manufacturingoption', <ManufacturingOptionView />);
        router.registerRoute('/configuration/packaging/attributes/manufacturingoption/{id}', <ManufacturingOptionView />);
        router.registerRoute('/configuration/packaging/attributes/converting', <PackagingConvertingMethodAttributeListView />);
        router.registerRoute('/configuration/packaging/attributes/converting/{id}', <PackagingConvertingMethodAttributeView />);
        router.registerRoute('/configuration/packaging/attributes/converting/{id}/edit', <PackagingConvertingMethodAttributeEditView />);

        router.registerRoute('/configuration/feature/attributes', <ConfigurationFeatureAttributesHomeScreen />);
        router.registerRoute('/configuration/feature/attributes/zipper', <FeatureZipperAttributeView />);
        router.registerRoute('/configuration/feature/attributes/hanghole', <FeatureHangholeAttributeView />);
        router.registerRoute('/configuration/feature/attributes/tearnotch', <FeatureTearnotchAttributeView />);
        router.registerRoute('/configuration/feature/attributes/bottomfill', <FeatureBottomFillAttributeView />);
        router.registerRoute('/configuration/feature/attributes/core', <FeatureCoreAttributeView />);
        router.registerRoute('/configuration/feature/attributes/colorspecs', <FeatureColorSpecAttributeView />);
        router.registerRoute('/configuration/feature/attributes/eyemarks', <FeatureEyemarkAttributeView />);
        router.registerRoute('/configuration/feature/attributes/vents', <VentAttributeView />);
        router.registerRoute('/configuration/feature/attributes/sealwidth', <FeatureSealWidthAttributeView />);
        router.registerRoute('/configuration/feature/attributes/qrcodetype', <QrCodeTypeAttributeView />);
        router.registerRoute('/configuration/feature/attributes/roundedcorners', <FeatureRoundedCornerAttributeView />);
        router.registerRoute('/configuration/feature/attributes/doublecuts', <FeatureDoubleCutAttributeView />);
        router.registerRoute('/configuration/feature/attributes/unwinddirections', <UnwindDirectionView />);
        router.registerRoute('/configuration/feature/attributes/gussettypes', <FeatureGussetTypeAttributeView />);
        router.registerRoute('/configuration/feature/attributes/outsourcedfeatures', <OutsourcedFeatureView />);
        router.registerRoute('/configuration/feature/attributes/outsourcedfeatures/{id}', <OutsourcedFeatureView />);
        router.registerRoute('/configuration/features/locations', <FeatureDefaultLocationListView />);
        router.registerRoute('/configuration/features/locations/{id}/edit', <FeatureDefaultLocationListEditView />);

        router.registerRoute('/configuration/structurespecs', <ConfigurationStructureSpecsHomeScreen />);
        router.registerRoute('/configuration/structurespecs/features', <StructureSpecFeatureView />);
        router.registerRoute('/configuration/structurespecs/features/{id}', <StructureSpecFeatureView />);
        router.registerRoute('/configuration/structurespecs/testmethods', <StructureSpecTestMethodsView />);
        router.registerRoute('/configuration/structurespecs/testmethods/{id}', <StructureSpecTestMethodsView />);
        router.registerRoute('/configuration/structurespecs/properties', <StructureSpecPropertiesDefinitionView />);
        router.registerRoute('/configuration/structurespecs/properties/{id}', <StructureSpecPropertiesDefinitionView />);
        router.registerRoute('/configuration/structurespecs/filmwidth', <FilmWidthDefinitionView />);
        router.registerRoute('/configuration/structurespecs/filmwidth/{id}', <FilmWidthDefinitionView />);
        router.registerRoute('/configuration/structurespecs/items', <ItemsView />);
        router.registerRoute('/configuration/structurespecs/items/{id}', <ItemsView />);
        router.registerRoute('/configuration/structurespecs/films/mapping', <FilmWidthMappingView />);
        router.registerRoute('/configuration/structurespecs/prelam', <PrelamRegistryView config={config} />);
        router.registerRoute('/configuration/structurespecs/uom', <PhysicalPropertiesListView />);
        router.registerRoute('/configuration/structurespecs/uom/{id}', <PhysicalPropertiesDetailsView />);
        router.registerRoute('/configuration/structurespecs/uom/{id}/edit', <PhysicalPropertiesEditView />);

        router.registerRoute('/configuration/dimensions', <StandardSizesListView />);
        router.registerRoute('/configuration/dimensions/{id}', <StandardSizesEditorView />);
        router.registerRoute('/configuration/dimensions/new', <StandardSizesEditorView />);

        router.registerRoute('/configuration/regions', <RegionalSupportConfigurationView />);
        router.registerRoute('/configuration/regions/languages', <LanguageListView />);
        router.registerRoute('/configuration/regions/translations', <TranslationView />);
        router.registerRoute('/configuration/regions/vocabulary', <TranslationView />);

        router.registerRoute('/configuration/press', <PressConfigurationView />);

        router.registerRoute('/configuration/dieline', <DielineConfigurationHomeView />);
        router.registerRoute('/configuration/dieline/convertingmethods', <DielineConfigurationConvertingMethodsView />);
        router.registerRoute('/configuration/dieline/hangholes', <DielineConfigurationHangHolesView />);
        router.registerRoute('/configuration/dieline/tearnotches', <DielineConfigurationTearNotchesView />);
        router.registerRoute('/configuration/dieline/zippers', <DielineConfigurationZippersView />);
        router.registerRoute('/configuration/dieline/eyemarks', <DielineConfigurationEyemarksView />);
        router.registerRoute('/configuration/dieline/bottomfills', <DielineConfigurationBottomFillsView />);
        router.registerRoute('/configuration/dieline/valves', <DielineConfigurationValvesView />);
        router.registerRoute('/configuration/dieline/sealwidth', <DielineConfigurationSealWidthView />);
        router.registerRoute('/configuration/dieline/roundedcorners', <DielineConfigurationRoundedCornersView />);
        router.registerRoute('/configuration/dieline/doublecut', <DielineConfigurationDoubleCutView />);
        router.registerRoute('/configuration/dieline/qrcodetype', <DielineConfigurationQrCodeTypeView />);
        router.registerRoute('/configuration/dieline/gussetType', <DielineConfigurationGussetTypeView />);
        router.registerRoute('/configuration/dieline/finishType', <DielineConfigurationFinishTypesView />);   

        router.registerRoute('/configuration/settings', <SettingsView />);

        router.registerRoute('/configuration/releases', <ReleaseView />);

        router.registerRoute('/products', <ProductHomeView/>);
        router.registerRoute('/products/all', <ProductListView />);
        router.registerRoute('/products/{id}', <ProductDisplayView />);
        router.registerRoute('/products/{id}/edit', <ProductEditView />);
        router.registerRoute('/products/new', <ProductEditView />);
        router.registerRoute('/products/review', <ProductReviewView />);
        router.registerRoute('/products/review/{id}', <ProductReviewEditView  />);


        router.registerRoute('/structurespecs', <StructureSpecListView config={config} />);
        router.registerRoute('/structurespecs/import', <StructureSpecImportView config={config} />);
        router.registerRoute('/structurespecs/sheets', <StructureSpecSheetRegistryView config={config} />);
        router.registerRoute('/structurespecs/new', <StructureSpecEditView config={config} />);
        router.registerRoute('/structurespecs/{id}', <StructureSpecView config={config} />);
        router.registerRoute('/structurespecs/{id}/edit', <StructureSpecEditView config={config} />);
        router.registerRoute('/structurespecs/requests', <StructureSpecRequestView config={config} />);
        router.registerRoute('/structurespecs/requests/{id}', <StructureSpecRequestView config={config} />);


        router.registerRoute('/packaginggroups', <ProductGroupListView />);
        router.registerRoute('/packaginggroups/{id}', <ProductGroupDisplayView config={config} />);
        router.registerRoute('/packaginggroups/{id}/edit', <ProductGroupEditView config={config} />);
        router.registerRoute('/packaginggroups/new', <ProductGroupEditView config={config} />);

        router.registerRoute('/rules', <RulesHomeView />);
        router.registerRoute('/rules/{id}', <RuleDetailsView />);
        router.registerRoute('/rules/new', <RuleEditView />);
        router.registerRoute('/rules/{id}/edit', <RuleEditView />); 

        router.registerRoute('/certifications', <CertificationHomeView />);
        router.registerRoute('/certifications/types', <CertificationTypeView />);
        router.registerRoute('/certifications/certificates', <CertificateView />);
        router.registerRoute('/certifications/certificates/{id}', <CertificateView />);
        

        router.registerRoute('/audit', <AuditView />);
    }

    const onRouting = (router, routeInfo) => {
        Logger.writeDebug(`Router: setting Route:`, routeInfo);
    }

    return (
        <div className="order">

            
            <BackSplashComponent capabilityRegistry={capabilities} id={CapabilityName.BackSplash} />
            <LoaderComponent capabilityRegistry={capabilities} id={CapabilityName.Loader} />
            <ModalComponent capabilityRegistry={capabilities} id={CapabilityName.Modal} />
            <NotificationComponent capabilityRegistry={capabilities} id={CapabilityName.Notification} />            
            <LegacyModalComponent />
            <LegacyConfirmationComponent />
            <LegacyAlertsComponent />
            <LegacyLoaderComponent />

            {
                config && 
                <SettingsContext.Provider value={config.settings}>
                    <UserContext.Provider value={config.user}>
                        <CapabilitiesContext.Provider value={config.capabilities}>
                            <Layout notificationSubscribers={config.notificationSubscribers}>
                                    <RouteContent onRegister={(router) => { onRegister(router) }} onRouting={(router, routeInfo) => { onRouting(router, routeInfo) }} notificationSubscribers={config.notificationSubscribers} />
                            </Layout>
                        </CapabilitiesContext.Provider>
                    </UserContext.Provider>
                </SettingsContext.Provider>                
            }
        </div>
    );
}
