import React, { useContext, useState } from 'react';
import { Tile } from '../../../core/Tile';
import { ImageTitle } from '../../../core/ImageTitle';
import { ApiDataAccess } from '../../../infrastructure/ApiDataAccess';import { DataManager } from '../../../dataControls/DataManager';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { DropdownDataControl } from '../../../dataControls/DropdownDataControl';
import { TextboxControl } from '../../../controls/TextboxControl';
import { GenericComponent } from '../../../dataForms/GenericComponent';
import { Item } from './Item';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';
import { RoundIndicator } from '../../../controls/RoundIndicator';
import { ExpandableSection } from '../../../controls/ExpandableSection';
import { MaterialEditorComponent } from '../../StructureSpec/Controls/MaterialEditorComponent';
import { Alert } from '../../../components/Components';
import { Card } from '../../../components/Containers';
import { ItemCommentModal } from './ItemCommentModal';



export class ConfigurationStructureSpecsHomeScreen extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Structure Spec Sheets' image='/images/attributes/material.png' />
                <div className='tile-container'>
                    <Tile title='Features' onClick={() => window.Router.setRoute('/configuration/structurespecs/features')} />
                    <Tile title='Test Methods' onClick={() => window.Router.setRoute('/configuration/structurespecs/testmethods')} />
                    <Tile title='Measure Units' onClick={() => window.Router.setRoute('/configuration/structurespecs/uom')} />
                    <Tile title='Physical Properties' onClick={() => window.Router.setRoute('/configuration/structurespecs/properties')} />
                    <Tile title='Film Width' onClick={() => window.Router.setRoute('/configuration/structurespecs/filmwidth')} />
                    <Tile title='Film Alternatives' onClick={() => window.Router.setRoute('/configuration/structurespecs/films/mapping')} />
                    <Tile title='Prelam' onClick={() => window.Router.setRoute('/configuration/structurespecs/prelam')} />
                </div>
            </div>
        );
    }
}


export class StructureSpecFeatureView extends React.Component {

    render() {
        return (
            <div className="view">
                <h1>Structure Spec Features</h1>
                <GenericComponent dataObject='structureSpecFeatures' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class StructureSpecTestMethodsView extends React.Component {

    render() {
        return (
            <div className="view">
                <h1>Test Methods</h1>
                <GenericComponent dataObject='testMethods' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class StructureSpecPropertiesDefinitionView extends React.Component {

    render() {
        return (
            <div className="view">
                <h1>Properties</h1>
                <GenericComponent dataObject='structureSpecPhysicalPropertyDefinitions' dataId={this.props.param_0} enableClone={false} />
            </div>
        );
    }
}

export class ItemsView extends React.Component {
    constructor(props) {
        super(props);
        this.list = null;

        this.onPublishToggle = this.onPublishToggle.bind(this);
        this.openCommentModal = this.openCommentModal.bind(this);
        this.close = this.close.bind(this);
    }

    getFilmWidth(item) {
        if (item.typeCode == 'FILM' || item.typeCode == 'PFILM' || item.typeCode == 'PRELAM') {
            return item.filmWidth;
        }
        else {
            return 'N/A';
        }
    }

    onPublishToggle(item, value, cntrl) {
        var self = this;
        var feature = new Item(item.id);
        feature.publish(value)
            .then(() => {
                self.list.refresh(null, true);
            })
            .catch(ex => {
                self.list.refresh(null, true);
            })
    }

    onRefreshItemsClick() {
        var self = this;
        var dataAccess = new ApiDataAccess('/data');
        dataAccess.post('/items/refresh', {})
            .then(data => { self.list.refresh(); })
            .catch(error => { window.Alerts.showError('Could not refresh List!'); });
    }

    openCommentModal(item) {
        window.Modal.show(
            modal => {
                return (<ItemCommentModal modal={modal} visible={true} item={item} />);
            }
        )
    }

    close() {
        window.Modal.hide();
    }


    render() {
        return (
            <div className="view">
                <h1>Items</h1>
                <button className='btn btn-primary' onClick={() => { this.onRefreshItemsClick() }}><span className='glyphicon glyphicon-refresh'></span> Refresh Items</button>
                <Alert type='info'>Items are imported from Dynamics NAV and cannot be modified.</Alert>
                <PageableDataObjectList dataObject='items'
                    overrideBaseUrl="items/pagable"
                    onInit={ctrl => this.list = ctrl}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-1 mobile-hidden'>Id</div>
                                <div className='col col-1 mobile-hidden'>Code</div>
                                <div className='col col-1 mobile-hidden'>Type</div>
                                <div className='col col-3'>Description</div>
                                <div className='col col-2'>Width</div>
                                <div className='col col-2'>Comment</div>
                                <div className='col col-2 mobile-hidden'>Date</div>
                                <div className='col col-2 mobile-hidden'>
                                    <span className='pull-right'>Published</span>
                                </div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div className='row' key={`row_${idx}`}>
                                <div className='col col-1 mobile-hidden'>{item.id}</div>
                                <div className='col col-1 mobile-hidden'>{item.code}</div>
                                <div className='col col-1 mobile-hidden'>{item.typeCode}</div>
                                <div className='col col-3'>{item.description}</div>
                                <div className='col col-2'>{this.getFilmWidth(item)}</div>
                                {
                                    item.isPublished === 'True' ?
                                        <div className='col col-2'>
                                            {!item.comment ?
                                                <button className='btn btn-secondary' onClick={() => this.openCommentModal(item)}>Add Supply Chain Comment</button>
                                                :
                                                <button className='btn btn-primary' onClick={() => this.openCommentModal(item)}>View Comment</button>
                                            }
                                        </div>
                                        :
                                        <div className='col col-2'>
                                        </div>
                                }
                                <div className='col col-2 mobile-hidden'>{item.createDate}</div>
                                <div className='col col-2 mobile-hidden'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => this.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        );
                    }} />
            </div>);
    }
}





export class FilmWidthDefinitionView extends React.Component {

    render() {
        return (
            <div className="view">
                <h1>Film Width</h1>
                {window.CONFIG.JOB_TEMPLATES_DISABLED
                    ? <div>
                        <PageableDataObjectList dataObject='filmWidth'
                            onRenderTitleRow={() => {
                                return (
                                    <div className='row'>
                                        <div className='col col-1 mobile-hidden'>Id</div>
                                        <div className='col col-1'>Valid</div>
                                        <div className='col col-2'>Width</div>
                                        <div className='col col-2'>Usable Width</div>
                                        <div className='col col-2'>UOM</div>
                                        <div className='col col-2 mobile-hidden'>Date</div>
                                        <div className='col col-2 mobile-hidden'>User</div>
                                    </div>
                                );
                            }}
                            onRenderRow={(item, idx) => {
                                return (
                                    <div className='row' key={`row_${idx}`}>
                                        <div className='col col-1 mobile-hidden'>{item.id}</div>
                                        <div className='col col-1'><RoundIndicator indicate={parseInt(item.dynamicsBridgeId) > 0 ? 'success' : 'error'} /></div>
                                        <div className='col col-2'>{item.width}</div>
                                        <div className='col col-2'>{item.printableWidth}</div>
                                        <div className='col col-2'>{item.widthUom}</div>
                                        <div className='col col-2 mobile-hidden'>{item.createDate}</div>
                                        <div className='col col-2 mobile-hidden'>{item.createUser}</div>
                                    </div>
                                );
                            }} />
                    </div>
                    : <GenericComponent dataObject='filmWidth' dataId={this.props.param_0} enableClone={false} />
                }
            </div>
        );
    }
}


export class FilmWidthMappingView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedId: 0,
            mappings: null
        };

        this.items = null;
        this.getItemsAsync()
            .then(items => {
                this.items = items;
                this.getMappingsAsync()
                    .then(mappings => {
                        this.setState({
                            mappings: mappings
                        });
                    });
            });

        this.onEditClick = this.onEditClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }


    getMappingsAsync() {
        return new Promise((resolve, reject) => {
            let dataAccess = new ApiDataAccess('/data/alternativefilmmappings');
            dataAccess.get('/')
                .then(data => { resolve(data); })
                .catch(ex => { reject(ex) });
        });
    }

    getItemsAsync() {
        return new Promise((resolve, reject) => {
            let dataAccess = new ApiDataAccess('/data/items');
            dataAccess.get('/')
                .then(data => { resolve(data); })
                .catch(ex => { reject(ex) });
        });
    }

    getItemDescription(id) {
        var item = this.items.find(e => e.id === id);
        return item ? item.description : 'N/A';
    }

    onEditClick(mapping) {
        this.setState({
            selectedId: mapping.id
        });
    }

    onDeleteClick(mapping) {
        window.Confirmation.show('Delete Mapping', 'Are you sure to delete this mapping?',
            () => {
                let dataAccess = new ApiDataAccess('/data/alternativefilmmappings');
                dataAccess.delete(`/${mapping.id}`)
                    .then(data => {
                        window.Alerts.showInfo('The mapping has been deleted!');
                        this.refresh();
                    });
            }
        );
    }

    refresh() {
        this.setState({
            selectedId: 0
        });

        this.getMappingsAsync()
            .then(mappings => {
                this.setState({
                    mappings: mappings
                });
            });
    }


    render() {
        return (
            <div className="view">
                <h1>Film Alternatives</h1>

                <div className='generic-list-component'>
                    <div className='table'>
                        <div className='table-header'>
                            <div className='header row background-black color-white'>
                                <div className='col col-5'>
                                    <p>Narrow Web</p>
                                </div>
                                <div className='col col-5'>
                                    <p>Wide Web</p>
                                </div>
                                <div className='col col-2'>
                                </div>
                            </div>
                        </div>

                        {this.state.mappings &&
                            <div className='body'>
                                {this.state.mappings.map((mapping, idx) => {
                                    return (
                                        <div key={`item${idx}`}>
                                            {this.state.selectedId == mapping.id
                                                ? <FilmWidthMappingEditor value={this.state.selectedId} onComplete={() => { this.refresh() }} />
                                                :
                                                <div className='row'>
                                                    <div className='col col-5'>
                                                        <p>{this.getItemDescription(mapping.narrowFilmId)}</p>
                                                    </div>
                                                    <div className='col col-5'>
                                                        <p>{this.getItemDescription(mapping.wideFilmId)}</p>
                                                    </div>
                                                    <div className='col col-2'>
                                                        <button className='btn btn-secondary-outline pull-right' onClick={() => { this.onDeleteClick(mapping) }}><span className='glyphicon glyphicon-trash'></span></button>
                                                        <button className='btn btn-primary pull-right' onClick={() => { this.onEditClick(mapping) }}><span className='glyphicon glyphicon-pencil'></span></button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        }

                    </div>

                    {!this.state.selectedId && this.items &&
                        <FilmWidthMappingEditor onComplete={() => { this.refresh() }} />
                    }

                </div>
            </div>
        );
    }
}


export class FilmWidthMappingEditor extends React.Component {
    constructor(props) {
        super(props);

        this.wideItems = null;
        this.narrowItems = null;
        this.state = {
            isValid: false
        }

        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.dataManager = new DataManager('alternativefilmmappings', this.onControlBound, this.onControlUnbound, this.onValidated);


        this.onAddClick = this.onAddClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onNarrowWebSelected = this.onNarrowWebSelected.bind(this);

    }


    componentDidMount() {

        Promise.all([
            this.dataManager.getDataSourceItems('WideFilmItems'),
            this.dataManager.getDataSourceItems('NarrowFilmItems')
        ])
            .then(results => {
                this.wideItems = results[0];
                this.narrowItems = results[1];
                this.loadContext();
            })
    }

    loadContext() {
        var self = this;

        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.props.value) {
                    dataManager.loadContext(this.props.value)
                        .then(data => {
                            dataManager.bindControls();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                    self.forceUpdate();
                }

                //3) onControlBound --> Set Value
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }


    onControlUnbound(control, propertyName) {
    }

    onAddClick() {
        this.dataManager.saveContext()
            .then(data => {
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while saving the Mapping');
            });
    }

    onCancelClick() {
        if (this.props.onComplete) {
            this.props.onComplete();
        }
    }

    onNarrowWebSelected(filmId) {
        if (!this.props.value) {
            let narrowItem = this.narrowItems.find(e => e.id === filmId);
            if (narrowItem) {
                let wideItem = this.wideItems.find(e => e.description === narrowItem.description);
                if (wideItem) {
                    this.dataManager.setValue('wideFilmId', wideItem.id);
                }
            }
        }
    }


    render() {
        return (
            <div className='table'>
                <div className='body'>
                    <div className='row'>
                        <div className='col col-5'>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='narrowFilmId' onChange={item => { this.onNarrowWebSelected(item) }} />
                        </div>
                        <div className='col col-5'>
                            <DropdownDataControl dataManager={this.dataManager} propertyName='wideFilmId' />
                        </div>
                        <div className='col col-2'>
                            <button disabled={!this.state.isValid} className='btn btn-secondary pull-right' onClick={this.onAddClick}><span className='glyphicon glyphicon-ok'></span></button>

                            {this.props.value &&
                                <button className='btn btn-secondary-outline pull-right' onClick={this.onCancelClick}><span className='glyphicon glyphicon-remove'></span></button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export class PrelamRegistryView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: null
        }

        this.onListItemSelected = this.onListItemSelected.bind(this);
        this.onSaved = this.onSaved.bind(this);
    }

    onListItemSelected(item) {
        this.setState({
            selectedItem: item
        });
    }

    onSaved() {
        this.setState({
            selectedItem: null
        });
    }

    render() {
        return (
            <div className="view">
                {!this.state.selectedItem
                    ? <PrelamRegistryList config={this.props.config} onSelected={this.onListItemSelected} />
                    : <PrelamRegistryEditorView config={this.props.config} item={this.state.selectedItem} onComplete={this.onSaved} />
                }
            </div>);
    }
}


export class PrelamRegistryList extends React.Component {
    constructor(props) {
        super(props);

        this.controls = {};
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onToggle = this.onToggle.bind(this);
    }

    onRowSelected(item) {
        if (this.props.onSelected) {
            this.props.onSelected(item);
        }
    }

    onToggle(id, value) {

        window.Confirmation.show('Reset Prelam Definition', 'Are you sure to reset this prelam definition? Assigned Films and Adhesives will be removed.',
            () => {
                var dataAccess = new ApiDataAccess('/data/prelamStructureRegistryItems');
                dataAccess.post(`/${id}/reset`, {})
                    .then(data => { this.controls.list.refresh(); })
                    .catch(ex => { this.controls.list.refresh(); });
            },
            () => {
                this.controls.list.refresh();
            }
        );
    }

    render() {
        return (
            <div>
                <h1>Prelams</h1>
                <PageableDataObjectList dataObject='prelamStructureRegistryItems' onInit={ctrl => { this.controls.list = ctrl; }}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-1 mobile-hidden'>Id</div>
                                <div className='col col-1 mobile-hidden'>Code</div>
                                <div className='col col-4'>Name</div>
                                <div className='col col-1'>Width</div>
                                <div className='col col-2 mobile-hidden'>Date</div>
                                <div className='col col-2 mobile-hidden'>User</div>
                                <div className='col col-1 mobile-hidden'>Defined</div>
                            </div>
                        );
                    }}
                    onRenderRow={(item, idx) => {
                        return (
                            <div className='row clickable' key={`row_${idx}`}>
                                <div className='col col-1 mobile-hidden' onClick={() => { this.onRowSelected(item) }}>{item.id}</div>
                                <div className='col col-1 mobile-hidden' onClick={() => { this.onRowSelected(item) }}>{item.code}</div>
                                <div className='col col-4' onClick={() => { this.onRowSelected(item) }}>{item.name}</div>
                                <div className='col col-1' onClick={() => { this.onRowSelected(item) }}>{item.filmWidth}</div>
                                <div className='col col-2 mobile-hidden' onClick={() => { this.onRowSelected(item) }}>{item.createDate}</div>
                                <div className='col col-2 mobile-hidden' onClick={() => { this.onRowSelected(item) }}>{item.createUser}</div>
                                <div className='col col-1 mobile-hidden'><ToggleSwitchControl on={item.isPublished == 'True'} disabled={item.isPublished != 'True'} onChange={val => { this.onToggle(item.id, val) }} /> </div>
                            </div>
                        );
                    }} />
            </div>
        );
    }
}



export class PrelamRegistryEditorView extends React.Component {
    constructor(props) {
        super(props);

        this.item = this.props.item;
        this.isPublished = this.item.isPublished == 'True';
        this.filmWidth = parseFloat(this.item.filmWidth);

        this.state = {
            isValid: false
        }


        this.onControlBound = this.onControlBound.bind(this);
        this.onControlUnbound = this.onControlUnbound.bind(this);
        this.onValidated = this.onValidated.bind(this);
        this.dataManager = new DataManager('prelamstructures', this.onControlBound, this.onControlUnbound, this.onValidated);

        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }


    componentDidMount() {
        this.loadContext();
    }


    loadContext() {
        var self = this;

        this.dataManager.init()
            .then(dataManager => {
                //1) load Data
                if (this.item && this.isPublished) {
                    dataManager.loadContext(this.item.id)
                        .then(data => {
                            dataManager.bindControls();
                        });
                }
                else {
                    //2) bind Controls
                    dataManager.bindControls();
                    self.forceUpdate();
                }

                //3) onControlBound --> Set Value
            });
    }

    onControlBound(control, propertyName) {
        var value = this.dataManager.dataObject[propertyName];
        if (value) {
            control.setValue(value);
        }
    }

    onValidated(isValid) {
        this.setState({
            isValid: isValid
        });
    }


    onControlUnbound(control, propertyName) {
    }

    onSaveClick() {
        this.dataManager.dataObject.id = this.item.id;
        this.dataManager.saveContext()
            .then(data => {
                if (this.props.onComplete) {
                    this.props.onComplete();
                }
            })
            .catch(error => {
                window.Alerts.showError('An error occurred while saving the Prelam Definition');
            });
    }

    onCancelClick() {
        if (this.props.onComplete) {
            this.props.onComplete();
        }
    }

    render() {
        return (
            <div className="view">
                <h1>{this.item.name}</h1>

                <Card title='General' subtitle='Find below the information for this prelam structure:'>
                    <label htmlFor='description'>Code</label>
                    <p>{this.item.code}</p>

                    <label htmlFor='description'>Film Width</label>
                    <p>{this.item.filmWidth}</p>

                    <label htmlFor='description'>Name</label>
                    <p>{this.item.name}</p>
                </Card>

                <Card title='Films & Adhesive' subtitle='Add Films and Adhesives to construct the prelam:'>
                    <MaterialEditorComponent config={this.props.config} dataManager={this.dataManager} propertyName='items' filmWidth={this.filmWidth} isPrelam={true} />
                </Card>

                {this.state.isValid &&
                    <button className='btn btn-lg btn-primary' onClick={this.onSaveClick}>Save</button>
                }
                <button className='btn btn-lg btn-secondary-outline pull-right' onClick={this.onCancelClick}>Cancel</button>

            </div>);
    }
}
