import React from 'react';
import { Rule } from './Rule';
import { PageableDataObjectList } from '../../../dataControls/PageableDataObjectList';
import { ToggleSwitchControl } from '../../../controls/ToggleSwitchControl';

export class RulesListControl extends React.Component {

    constructor(props) {
        super(props);

        this.list = null;
        this.onPublishToggle = this.onPublishToggle.bind(this);
        this.rowSelected = this.rowSelected.bind(this);
        this.onMoveUpClick = this.onMoveUpClick.bind(this);
        this.onMoveDownClick = this.onMoveDownClick.bind(this);
    }


    onMoveUpClick(item) {
        var self = this;
        var rule = new Rule(item.id);
        rule.executePrioritizeUp()
            .then(() => {
                self.list.refresh();
            })
            .catch(ex => {
                window.Logger.writeError('Could not prioritize rule', ex);
                window.Alerts.showError('Could not move rule', ex);
            });
    }

    onMoveDownClick(item) {
        var self = this;
        var rule = new Rule(item.id);
        rule.executePrioritizeDown()
            .then(() => {
                self.list.refresh();
            })
            .catch(ex => {
                window.Logger.writeError('Could not prioritize rule', ex);
                window.Alerts.showError('Could not move rule', ex);
            });
    }


    onPublishToggle(item) {       
        var self = this;
        var feature = new Rule(item.id);
        var publish = item.isPublished !== 'True';
        feature.publish(publish)
            .then(() => {
                self.list.refresh();
            });
    }
    

    rowSelected(item) {
        if (this.props.onRowSelected) {
            this.props.onRowSelected(item);
        }
    }

    render() {
        var self = this;
        return (
            <div>
                <PageableDataObjectList dataObject='rules' onInit={list => { this.list = list }}
                    onRenderTitleRow={() => {
                        return (
                            <div className='row'>
                                <div className='col col-2'>Priority</div>
                                <div className='col col-4'>Name</div>
                                <div className='col col-2'>User</div>
                                <div className='col col-2'>Date</div>
                                <div className='col col-2'><div className='pull-right'>Published</div></div>
                            </div>
                        )
                    }}                    
                    onRenderRow={(item, idx, isFirstItem, isLastItem) => {                        
                        return (
                            <div key={`row_${item.id}_${idx}`} className='row'>
                                <div className='col col-2'>
                                    <button className='btn btn-primary btn-sm' disabled={isFirstItem == true} onClick={() => self.onMoveUpClick(item)}><span className='glyphicon glyphicon-chevron-up' /></button>
                                    <button className='btn btn-primary btn-sm' disabled={isLastItem == true} onClick={() => self.onMoveDownClick(item)}><span className='glyphicon glyphicon-chevron-down' /></button>
                                </div>

                                <div className='col col-4 clickable' onClick={() => self.rowSelected(item)}>{item.name}</div>
                                <div className='col col-2 clickable' onClick={() => self.rowSelected(item)}>{item.createUser}</div>
                                <div className='col col-2 clickable' onClick={() => self.rowSelected(item)}>{item.createDate}</div>

                                <div className='col col-2'>
                                    <div className='pull-right'>
                                        <ToggleSwitchControl on={item.isPublished === 'True'} onChange={(value, cntrl) => self.onPublishToggle(item, value, cntrl)} />
                                    </div>
                                </div>
                            </div>
                        )
                    }} />

            </div>
        );
    }
}