import React from 'react';
import { ApiDataAccess } from '../../infrastructure/ApiDataAccess';
import { StructureSpec } from './Controls/StructureSpec';
import { StructureSpecRegistry } from './Controls/StructureSpecRegistry';
import { ImageTitle } from '../../core/ImageTitle';
import { StructureSpecViewComponent } from './Controls/StructureSpecViewComponent';
import { StructureSpecEditorComponent } from './Controls/StructureSpecEditorComponent';
import { StructureSpecsImportComponent } from './Controls/StructureSpecsImportComponent';

export class StructureSpecListView extends React.Component {

    constructor(props) {
        super(props);
        this.dataAccess = new ApiDataAccess('/data');
        this.onItemSelected = this.onItemSelected.bind(this);
        this.onPublishToggle = this.onPublishToggle.bind(this);
    }

    onPublishToggle(item, value, list, toggle) {               
        var isPublished = !value;
        toggle.setValue(isPublished);

        var structureSpec = new StructureSpec(item.id);
        if (isPublished) {
            structureSpec.publish(false)
                .then(data => {                    
                    window.Alerts.showSuccess(`Structure Spec-${item.id} was unpublished`);
                    list.refresh(null, true);
                })
                .catch(ex => {
                    if (ex.Messages && ex.Messages.length > 0) {
                        window.Alerts.showInfo(ex.Messages[0]);
                    }
                    else {
                        window.Alerts.showError(`Could not unpublish SPEC-${item.id}`);
                    }
                    list.refresh(null, true);
                });                
        }
        else {
            structureSpec.publish(true)
                .then(data => {                    
                    window.Alerts.showSuccess(`Structure Spec-${item.id} was published`);
                    list.refresh(null, true);
                })
                .catch(ex => {

                    if (ex.exceptions && ex.exceptions.length > 0) {
                        window.Alerts.showError(ex.exceptions[0].message);
                    }
                    else if (ex.messages && ex.messages.length > 0) {
                        window.Alerts.showError(ex.messages[0].message);
                    }
                    else {
                        window.Alerts.showError(`Could not publish SPEC-${item.id}`);
                    }
                    list.refresh();
                });
        }
    }

    onItemSelected(item) {
        var isDefined = item.isDefined === 'True';
        if (isDefined) {
            window.Router.setRoute('/structurespecs/' + encodeURIComponent(item.id));
        }
        else {
            window.Router.setRoute('/structurespecs/' + encodeURIComponent(item.id) + '/edit');
        }        
    }


    render() {
        return (
            <div className="view">
                <ImageTitle text='Structure Specs' />               
                <StructureSpecRegistry onItemSelected={this.onItemSelected} onPublishToggle={this.onPublishToggle} />                
            </div>

        );
    }
}

export class StructureSpecView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Structure Specs' />
                <StructureSpecViewComponent config={this.props.config} dataId={this.props.param_0} />               
            </div>
        );
    }
}

export class StructureSpecEditView extends React.Component {


    render() {
        return (
            <div className="view">                
                <ImageTitle text='Structure Specs' />
                <StructureSpecEditorComponent config={this.props.config} dataId={this.props.param_0}/>
            </div>
        );
    }
}

export class StructureSpecImportView extends React.Component {

    render() {
        return (
            <div className="view">
                <ImageTitle text='Structure Specs Import' />
                <StructureSpecsImportComponent />
            </div>
        );
    }
}
