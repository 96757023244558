import React from 'react';
import { ProductGroupListComponent } from './Controls/ProductGroupListComponent';
import { ProductGroupViewComponent } from './Controls/ProductGroupViewComponent';
import { ProductGroupEditorComponent } from './Controls/ProductGroupEditorComponent';

export class ProductGroupListView extends React.Component {

    render() {
        return (
            <div className="view">
                <ProductGroupListComponent dataObject='productGroups' onRowSelected={item => window.Router.setRoute('/packaginggroups/' + encodeURIComponent(item.id)) } />
            </div>

        );
    }
}


export class ProductGroupDisplayView extends React.Component {

    render() {
        return (
            <div className="view">
                <ProductGroupViewComponent dataId={this.props.param_0} config={this.props.config} />
            </div>
        );
    }
}

export class ProductGroupEditView extends React.Component {

    render() {
        return (
            <div className="view">
                <ProductGroupEditorComponent dataId={this.props.param_0} config={this.props.config} />
            </div>
        );
    }
}