import React, { useState, useEffect } from 'react';
import { GenericDataAccess } from '../../../dataControls/GenericDataAccess';
import { Alert } from '../../../components/Components';

import './CertificateList.css'

export const CertificateList = ({ structureSpecId }) => {

    const [items, setItems] = useState(null);


    useEffect(() => {

        var dataAccess = new GenericDataAccess();
        var filter = `structure_spec_id=${structureSpecId} and published=1`;
        dataAccess.getList('certificateDetails', 10000, 0, filter, page => {
            console.log('XXXXX', page.items);
            setItems(page.items);
        });
    }, []);

    return (
        <div>
            {items && items.length > 0 ?
                <div className='generic-list-component certificates'>
                    <div className='table'>
                        <div className="table-header">
                            <div className='row'>
                                <div className='col col-1'>Id</div>
                                <div className='col col-4'>Name</div>
                                <div className='col col-3'>Converting Method</div>
                                <div className='col col-4'>Dimensions</div>
                            </div>
                        </div>

                        <div className='body'>
                            {items.map((item, idx) => {
                                return (
                                    <div key={`row${idx}`} className='row clickable' onClick={() => { window.Router.setRoute(`/certifications/certificates/${item.id}`) }}>
                                        <div className='col col-1'>{item.id}</div>
                                        <div className='col col-4'>{item.certificateName}</div>
                                        <div className='col col-3'>{item.convertingMethodName}</div>
                                        <div className='col col-4'><CertificateDimension item={item} /></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                :
                <Alert type='info'>No certifications are available for this material</Alert>
            }
        </div>
    )
}


export const CertificateDimension = ({ item }) => {


    return (
        <div className='table dimension'>
            <div className='body'>
                <div className='row'>
                    <div className='col col-3'><label>Length</label></div>
                    <div className='col col-9'>{parseFloat(item.lengthFrom).toFixed(4)} to {parseFloat(item.lengthTo).toFixed(4)}</div>
                </div>
                <div className='row'>
                    <div className='col col-3'><label>Width</label></div>
                    <div className='col col-9'>{parseFloat(item.widthFrom).toFixed(4)} to {parseFloat(item.widthTo).toFixed(4)}</div>
                </div>
                {item.gussetTo && item.gussetFrom && item.gussetTo !== null && item.gussetFrom !== null && 
                    <div className='row'>
                        <div className='col col-3'><label>Gusset</label></div>
                        <div className='col col-9'>{parseFloat(item.gussetFrom).toFixed(4)} to {parseFloat(item.gussetTo).toFixed(4)}</div>
                    </div>
                }
            </div>
        </div>
    )
}